export default defineNuxtRouteMiddleware(to => {
    const config = useRuntimeConfig();
    const projectName = config.public.projectName;
    if (projectName !== 'B2B') {
        return;
    }

    const userInfo = useCookie('apiEndpoint').value;
    const isActAsMember = useCookie('isActAsMember').value;
    // if (process.client) {
    // const userInfo = JSON.parse(localStorage.getItem("subagent-userInfo"));
    const isAdmin =
        !isActAsMember && (userInfo?.role === 'admin' || userInfo?.role === 'account_manager') ? true : false;
    const managerExtraViews = ['commission', 'team-members'];
    const token = useCookie('token');
    const afterLoginURL = useCookie('afterLoginURL');
    const isAuthenticated = token.value;

    if (!isAuthenticated && !to.path.includes('auth') && !to.path.includes('application-transfer')) {
        afterLoginURL.value = to.fullPath;
        return navigateTo('/auth/login');
        //Handling if Authenticated and path includes "auth" -OR- "commission", "team-members" in case the user is not a manager.
    } else if (
        isAuthenticated &&
        !to.path.includes('new-password') &&
        (to.path.includes('auth') || (!isAdmin && managerExtraViews.some(word => to.path.includes(word))))
    ) {
        return navigateTo('/');
    }

    if (
        isAuthenticated &&
        userInfo?.role === 'account_manager' &&
        ['university', 'institute'].some(word => to.path.includes(word))
    ) {
        return navigateTo('/');
    }
    // }
});
