export const state = () => ({
    sort: false,
    searchList: false,
    profileMenu: false,
    docMenu: false,
    bodyOverFlow: false,
    showFlyButton: false,
    showNotification: false,
    isActAsMember: false,
    actAsMemberData: {
        agentName: '',
        agentID: '',
    },
});

export const actions = {
    async bodyOverFlow({ state }) {
        state.bodyOverFlow = !state.bodyOverFlow;
    },

    setActAsMember({ state }, payload) {
        state.isActAsMember = payload;
    },

    setActAsMemberData({ state }, payload) {
        if (payload) {
            state.actAsMemberData = payload;
        }
    },
};
