<template>
    <small id="email-help" class="help-item text-danger d-flex gap-1">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_266_87612)">
                <path
                    d="M8 10.9651C7.55269 10.9651 7.17773 11.34 7.17773 11.7873C7.17773 12.2347 7.55269 12.6096 8 12.6096C8.43086 12.6096 8.82226 12.2347 8.80252 11.8071C8.82226 11.3368 8.4506 10.9651 8 10.9651Z"
                    fill="#D80027"
                />
                <path
                    d="M15.6107 13.8791C16.127 12.9878 16.1303 11.9254 15.6172 11.0374L10.4666 2.1175C9.95679 1.21959 9.03585 0.686768 8.00309 0.686768C6.97033 0.686768 6.0494 1.22288 5.53959 2.11421L0.382367 11.044C-0.130724 11.9419 -0.127435 13.0108 0.392235 13.9022C0.905326 14.7836 1.82297 15.3132 2.84915 15.3132H13.1373C14.1668 15.3132 15.091 14.777 15.6107 13.8791ZM14.4924 13.2345C14.2062 13.7278 13.6997 14.0206 13.134 14.0206H2.84586C2.28673 14.0206 1.7835 13.7344 1.50393 13.2509C1.22107 12.7609 1.21779 12.1754 1.50064 11.6821L6.65787 2.75558C6.93744 2.26551 7.43737 1.97607 8.00309 1.97607C8.56552 1.97607 9.06874 2.2688 9.34831 2.75887L14.5022 11.6853C14.7785 12.1655 14.7752 12.7444 14.4924 13.2345Z"
                    fill="#D80027"
                />
                <path
                    d="M7.79592 5.19265C7.40452 5.30448 7.16113 5.65969 7.16113 6.09056C7.18087 6.35039 7.19731 6.61352 7.21705 6.87335C7.27296 7.86335 7.32887 8.83362 7.38479 9.82363C7.40452 10.1591 7.66436 10.4025 7.99984 10.4025C8.33532 10.4025 8.59845 10.1427 8.61489 9.80389C8.61489 9.59997 8.61489 9.4125 8.63463 9.20529C8.67081 8.5705 8.71027 7.93571 8.74645 7.30093C8.76619 6.8898 8.80237 6.47866 8.8221 6.06753C8.8221 5.91953 8.80237 5.78796 8.74645 5.6564C8.57871 5.28803 8.18732 5.10055 7.79592 5.19265Z"
                    fill="#D80027"
                />
            </g>
            <defs>
                <clipPath id="clip0_266_87612">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <div class="slot-container">
            <slot />
        </div>
    </small>
</template>

<script setup></script>

<style lang="scss">
.help-item {
    margin-top: 2px;
    width: 100%;

    svg {
        margin-top: 1px;
    }

    .slot-container {
        width: 97%;
    }
}
</style>
