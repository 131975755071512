export const state = () => ({
    addressEn: [
        {
            id: 3,
            name: 'Saudi Arabia office address',
            address: 'Al Thumama Street, after Al Takhasusi Hall, AlSahafa District. Riyadh 13315',
        },
        {
            id: 2,
            name: 'Kuwait office address',
            address: 'Al-Soor Street, Al-Tijaria Tower, 35th floor',
        },
    ],
    addressAr: [
        {
            id: 3,
            name: 'عنوان مكتب المملكة العربية السعودية',
            address: 'شارع الثمامة بعد قاعة التخصصي حي الصحافة. الرياض 13315',
        },
        {
            id: 2,
            name: 'عنوان مكتب الكويت',
            address: 'شارع السور برج التجارية الدور 35',
        },
    ],
});
