export const state = () => ({
    cities: [],
    citiesList: [],
    citiesCountryList: [],
    countryNumber: 0,
    mark: '',
    citiesAddForm: [],
});

export const actions = {
    getCities({ state }, payload) {
        const { $axios } = useNuxtApp();
        $axios.get(`/countries/${payload}/cities`).then(res => {
            state.cities = res.data.data;
            state.citiesAddForm = res.data.data;
            state.citiesList = res.data.data.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    query: `&city_ids[]=${item.id}`,
                };
            });
        });
    },

    getCitiesCountries({ state }, payload) {
        if (!payload) {
            payload = '';
        } else {
            state.mark = '?';
        }
        let query = typeof payload === 'string' ? payload : payload.query;
        const { $axios } = useNuxtApp();
        $axios.get(`/cities${state.mark}${query}`).then(res => {
            state.cities = res.data.data;
            state.citiesCountryList = res.data.data.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
            if (payload?.onSuccess) {
                payload.onSuccess(res.data.data);
            }
        });
    },

    getCitiesAddForm({ state }, payload) {
        let cityId = '';
        if (payload) {
            cityId = `?country_ids[]=${payload}`;
        }
        const { $axios } = useNuxtApp();
        $axios.get(`/cities${cityId}`).then(res => {
            state.citiesAddForm = res.data.data;
        });
    },
};
