export const state = () => ({
    educationalLevel: [
        { id: 1, name: 'Foundation' },
        { id: 2, name: 'Undergraduate' },
        { id: 3, name: 'Graduate' },
        { id: 4, name: 'Postgraduate' },
        { id: 5, name: 'High School' },
    ],
    Levels: [],
});

export const actions = {
    getEducationalLevel({ state }) {
        const { $axios } = useNuxtApp();
        $axios.get(`/educational-levels`).then(res => {
            state.educationalLevel = res.data.data;
        });
    },
    getLevels({ state }) {
        const { $axios } = useNuxtApp();
        $axios.get(`/levels`).then(res => {
            state.Levels = res.data.data;
        });
    },
};
