<template>
    <div v-if="hasToken" class="fixed-nav-container">
        <div
            class="acting-as-member py-2 text-center text-white h4 d-flex justify-content-center gap-3"
            v-if="isActAsMember"
        >
            <button class="unset pointer" @click="cancelActAsMember">
                <img src="~/assets/img/back_arrow.svg" width="24" />
            </button>
            <div>
                {{ $t('backToYouView') }}
            </div>
        </div>
        <div class="container">
            <div class="nav-container">
                <nav class="container navbar navbar-expand-lg" :class="{ 'mt-7': isActAsMember }">
                    <nuxt-link class="navbar-brand" to="/">
                        <img src="/assets/img/common-imgs/color_logo.svg" alt="Acceptix" />
                    </nuxt-link>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <div class="notification-box" @click.stop="toggleNotifications">
                                <div @click="testNotifcation">
                                    <i
                                        badgeSeverity="secondary"
                                        v-badge.secondary="
                                            $store.state.notification.count >= 100
                                                ? '99+'
                                                : $store.state.notification.count
                                        "
                                        class="icomoon icon-bell"
                                        v-if="$store.state.notification.count > 0"
                                    />
                                    <i v-else class="icomoon icon-bell" />
                                </div>
                                <OverlayPanel
                                    ref="notificationsOP"
                                    append-to="body"
                                    :pt="{
                                        content: {
                                            style: 'padding: 0',
                                        },
                                    }"
                                >
                                    <LayoutNotification :hide-notifications="hideNotifications" />
                                </OverlayPanel>
                            </div>
                        </ul>

                        <div class="dropdown">
                            <button class="btn" type="button" @click.stop="toggleMenu">
                                <div class="user-info d-flex">
                                    <img
                                        class="me-2"
                                        :src="$store.state.profileImg?.image ? $store.state.profileImg?.image : image"
                                        alt="Avatar"
                                    />
                                    <div class="user text-start">
                                        <p class="welcome-message">welcome</p>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p class="user-name" :title="userName">{{ userName }}</p>
                                            <i class="icomoon icon-chevron_down" v-if="!isActAsMember"></i>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <OverlayPanel
                                ref="profileOP"
                                append-to="body"
                                :pt="{
                                    content: {
                                        style: 'padding: 0',
                                    },
                                }"
                            >
                                <div class="menu-box">
                                    <ul>
                                        <li v-if="userRole != 'account_manager'">
                                            {{ $t('myProfile') }} <i class="pi pi-user"></i>
                                            <LocLink
                                                class="stretched-link"
                                                @click.stop="closeBox"
                                                to="/profile"
                                            ></LocLink>
                                        </li>
                                        <li data-bs-toggle="modal" data-bs-target="#logoutModal">
                                            {{ $t('logout') }} <i class="pi pi-sign-out"></i>
                                        </li>
                                    </ul>
                                </div>
                            </OverlayPanel>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <div v-if="isActAsMember" class="mb-5"></div>
        <!-- Start Modal -->
        <div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="exampleModalLabel">Logout</h3>
                    </div>
                    <div class="modal-body p-0">Are you sure you want to logout?</div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn confirm-btn" @click="logOut" data-bs-dismiss="modal">
                            Yes
                        </button>
                        <button type="button" class="btn cancel-btn" @click="clearData" data-bs-dismiss="modal">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </div>
</template>

<script>
import api from '@/api';

export default {
    data() {
        return {
            userRole: null,
            userName: 'Username',
            image: '/assets/img/home-images/man 1.png',
            hasToken: false,
        };
    },

    computed: {
        isActAsMember() {
            return this.$store.state.functions.isActAsMember;
        },
    },

    mounted() {
        this.addRemoveNotificationsListener();
        this.hasToken = useCookie('token');
        const userInfo = useCookie('subagent_userInfo').value;
        this.userRole = userInfo?.role;
        this.userName = userInfo?.name ? userInfo?.name : 'Username';
        this.image = userInfo?.image ? userInfo?.image : '/assets/img/profile/imagePlaceHolder.jpg';
    },

    methods: {
        testNotifcation() {
            const subDomain = useCookie('subagent_userInfo').value.subDomain;
            api.post(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/notifications/make/read`).then(res => {
                this.$store.state.notification.count = 0;
                this.$store.dispatch('notification/getNotificationsListBell');
            });
        },

        closeBox() {
            this.$store.state.functions.profileMenu = false;
            this.$refs?.profileOP?.hide();
        },

        toggleMenu(event) {
            this.hideNotifications();
            if (this.isActAsMember) return;
            this.$store.state.functions.profileMenu = !this.$store.state.functions.profileMenu;
            this.$refs.profileOP.toggle(event);
        },

        async logOut() {
            this.$store.state.functions.profileMenu = false;
            // localStorage.removeItem("token");
            const token = useCookie('token');
            localStorage.removeItem('email');
            // localStorage.removeItem('userlogin');
            this.closeBox();
            const subDomain = useCookie('subagent_userInfo').value.subDomain;
            await api.post(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/logout`).then(res => {
                this.$store.state.login.profileMenu = false;
                this.$store.state.login.logout = true;
                token.value = null;
                this.$store.dispatch('profile/removeProfile');
                this.$router.push({
                    path: this.localePath('/auth/login'),
                });
            });
            // });
        },

        cancelActAsMember() {
            this.$store.dispatch('functions/setActAsMember', false);
            const isActAsMember = useCookie('isActAsMember');
            isActAsMember.value = false;
            this.$router.push({ path: '/team-members' }); //reload to back to manager view
        },

        toggleNotifications(event) {
            this.$store.state.functions.showNotifications = !this.$store.state.functions.showNotifications;
            this.$refs.notificationsOP.toggle(event);
        },

        hideNotifications() {
            this.$refs?.notificationsOP?.hide();
        },

        addRemoveNotificationsListener(add = true) {
            if (process.client) {
                if (add) {
                    window.addEventListener('scroll', this.handleScroll);
                } else {
                    window.removeEventListener('scroll', this.handleScroll);
                }
            }
        },

        handleScroll() {
            if (process.client && window.scrollY > 50) {
                this.hideNotifications();
                this.$refs?.profileOP?.hide();
                this.$store.state.functions.profileMenu = false;
            }
        },
    },

    beforeDestroy() {
        this.addRemoveNotificationsListener(false);
    },
};
</script>

<style lang="scss" scoped>
.nav-container {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    height: 121px;

    .user-info {
        img {
            width: toRem(38);
            height: toRem(38);
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
        }
    }
}

.menu-box {
    overflow: hidden;
    max-width: 300px;
    min-width: 241px;

    ul {
        li {
            padding: 20px;
            border-bottom: 1px solid #dadada;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            color: #222;
            position: relative;
            cursor: pointer;

            .stretched-link {
                position: absolute;
                z-index: 99;
                inset: 0;
            }

            @include hover(0.2) {
                background-color: #ababab11;
            }

            i {
                color: #ababab;
                font-size: 1.2rem;
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

nav {
    padding: toRem(24) 0;
    border-bottom: 1px solid $light-grey9;
    position: fixed;
    background-color: white;
    top: 0;
    width: 100%;
    z-index: 3;

    .navbar-nav {
        margin-inline-start: auto;

        .notification-box {
            position: relative;

            i {
                cursor: pointer;
                font-size: toRem(15);
                @include colorPrimary;
            }
        }
    }

    .notification-box {
        margin: 0 toRem(23);

        .icon-bell {
            color: $primary-color;
            font-size: toRem(17);
            border: toRem(1) solid $light-grey5;
            box-shadow: 0 toRem(4) toRem(8) 0 $dark3;
            border-radius: 50%;
            background-color: $white;
            padding: toRem(11);
            cursor: pointer;
        }
    }

    .dropdown {
        &::before {
            content: '';
            height: toRem(32);
            width: toRem(2);
            position: absolute;
            background-color: $light-grey7;
            top: 18%;
        }

        .btn {
            border: none;
            padding-left: toRem(25);
            padding-right: toRem(25);
        }

        .dropdown-item {
            text-align: start;
        }
    }

    .user {
        .icon-chevron_down {
            font-size: toRem(9.5);
            padding: toRem(10) 0 0;
            padding-inline-start: toRem(12);
        }

        .welcome-message {
            font-size: toRem(12);
            color: $light-grey6;
            margin-bottom: toRem(2);
        }

        .user-name {
            padding-top: toRem(4);
            font-size: toRem(16);
            text-transform: capitalize;
            white-space: nowrap;
            min-width: toRem(100);
            max-width: toRem(150);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.language-icon {
    cursor: pointer;
    color: $primary-color;
    font-size: toRem(32);

    p {
        font-size: toRem(14);
        padding-inline-start: toRem(5);
    }
}

.mt-7 {
    margin-top: 1.75rem;
}

.acting-as-member {
    background-color: $primary-color;
    position: fixed;
    left: 93px;
    right: 0;
    z-index: 4;
    text-transform: capitalize;

    .pointer {
        cursor: pointer;
    }
}

@media (min-width: 992px) {
    nav {
        padding: toRem(24) 0;

        .notification-box {
            .icon-bell {
                font-size: toRem(14);
                padding: toRem(9);
            }
        }

        .dropdown {
            &::before {
                height: toRem(30);
            }
        }

        .user {
            .icon-chevron_down {
                font-size: toRem(7);
                padding: toRem(6) 0 0;
                padding-inline-start: toRem(10);
            }

            .welcome-message {
                font-size: toRem(12);
            }

            .user-name {
                font-size: toRem(13.5);
            }
        }
    }

    .language-icon {
        font-size: toRem(28);

        p {
            font-size: toRem(12);
            padding-left: toRem(4);
        }
    }
}

@media (min-width: 1900px) {
    nav {
        // padding: toRem(35) 0;

        .notification-box {
            .icon-bell {
                font-size: toRem(17);
                padding: toRem(11);
            }
        }

        .dropdown {
            &::before {
                height: toRem(32);
            }
        }

        .user {
            .icon-chevron_down {
                font-size: toRem(9.5);
                padding: toRem(10) 0 0;
                padding-inline-start: toRem(12);
            }

            .welcome-message {
                font-size: toRem(12);
            }

            .user-name {
                font-size: toRem(16);
            }
        }
    }

    .language-icon {
        font-size: toRem(32);

        p {
            font-size: toRem(14);
            padding-left: toRem(5);
        }
    }
}
</style>

<style lang="scss">
.fixed-nav-container {
    .modal-dialog {
        width: toRem(420);
        max-width: none;

        .modal-content {
            border-radius: toRem(10);
            padding: toRem(25) toRem(40);

            .modal-title {
                font-size: toRem(20);
                font-family: $font-m-b;
                color: $primary-color;
            }

            .modal-header {
                margin-bottom: 0;
                border-bottom: none;
                padding: 0;
                justify-content: center;
            }

            .modal-body {
                font-size: toRem(20);
                font-family: $font-m-sb;
                color: $dark8;
                text-align: center;
                margin: toRem(30) 0;
            }

            .modal-footer {
                border: none;
                padding: 0 0 0.5rem;
                display: flex;
                gap: 5%;
            }

            .confirm-btn {
                margin: 0;
                @include btnGenerator(
                    $padding: toRem(10) toRem(10),
                    $font-size: toRem(18),
                    $color: $white,
                    $width: 45%,
                    $background-color: $primary-color,
                    $border-radius: toRem(5),
                    $font-family: $font-m-b
                );
            }

            .cancel-btn {
                margin: 0;
                @include btnGenerator(
                    $padding: toRem(10) toRem(10),
                    $font-size: toRem(18),
                    $color: $primary-color,
                    $background-color: $white,
                    $width: 45%,
                    $border-radius: toRem(5),
                    $border: 1px solid $primary-color,
                    $font-family: $font-m-b
                );
            }
        }
    }
}
</style>
