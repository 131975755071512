import axios from 'axios';
import api from '@/api';

export default defineNuxtPlugin(nuxtApp => {
    const instance = axios.create({
        baseURL: `https://${useRuntimeConfig().public.baseURL}`,
    });

    const requestInterceptor = {
        onFulfilled: function (config) {
            const userInfo = useCookie('subagent_userInfo');
            const userToken = useCookie('token');

            if (userInfo) {
                const subdomain = userInfo.value?.subDomain;
                if (subdomain) {
                    const baseURL = `https://${subdomain}.${useRuntimeConfig().public.baseURL}`;
                    instance.defaults.baseURL = baseURL;
                    config.baseURL = baseURL;
                }
            }

            if (nuxtApp.$i18n.locale._value) {
                config.headers['Accept-Language'] = nuxtApp.$i18n.locale._value;
            }
            if (userToken) {
                config.headers['Authorization'] = `Bearer ${userToken.value}`;
            }

            config.params = { ...config.params };
            const actAsMemberData = useCookie('actAsMemberData');
            const isActAsMember = useCookie('isActAsMember');
            if (nuxtApp.$store.state.functions.isActAsMember || isActAsMember.value) {
                if (config.method === 'post' || config.method === 'put') {
                    config.data['admin_id'] =
                        nuxtApp.$store.state.functions.actAsMemberData.agentID || actAsMemberData.value.agentID;
                } else if (config.method === 'get') {
                    config.params['admin_id'] =
                        nuxtApp.$store.state.functions.actAsMemberData.agentID || actAsMemberData.value.agentID;
                }
            }

            return config;
        },
        onRejected: function (error) {
            return Promise.reject(error);
        },
    };

    const responseInterceptor = {
        onFulfilled: function (response) {
            return response;
        },
        onRejected: function (error) {
            if (error.response && error.response.status === 401) {
                const apiEndpoint = useCookie('apiEndpoint');
                apiEndpoint.value = null;
                const userToken = useCookie('token');
                userToken.value = null;
                // useCookie('subagent_userInfo').value = null;
                const afterLoginURL = useCookie('afterLoginURL');
                afterLoginURL.value = null;
                location.reload();
            }
            if (error.response && error.response.status === 403) {
                console.log(error.response.data.errors);
                return Promise.reject(error.response.data.errors);
            }
            return Promise.reject(error);
        },
    };

    instance.interceptors.request.use(requestInterceptor.onFulfilled, requestInterceptor.onRejected);

    instance.interceptors.response.use(responseInterceptor.onFulfilled, responseInterceptor.onRejected);

    api.interceptors.request.use(requestInterceptor.onFulfilled, requestInterceptor.onRejected);

    api.interceptors.response.use(responseInterceptor.onFulfilled, responseInterceptor.onRejected);

    nuxtApp.provide('axios', instance);
});
