export const waitForCookie = (name, value, timeout = 3000) => {
    return new Promise((resolve, reject) => {
        const cookie = useCookie(name, {
            sameSite: 'none',
            secure: true,
        });
        cookie.value = value;

        let checkInterval = 100; // Check every 100ms
        let elapsedTime = 0;

        let checkCookie = setInterval(() => {
            if (useCookie(name)?.value) {
                clearInterval(checkCookie);
                resolve(true);
            } else if (elapsedTime > timeout) {
                clearInterval(checkCookie);
                reject(new Error('Timeout waiting for cookie to be set'));
            }
            elapsedTime += checkInterval;
        }, checkInterval);
    });
};
