<template>
    <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
        <Body @click="clickEvent">
            <!-- <LayoutTopHeader :showSelect="false" /> -->
            <!-- <LayoutHeader /> -->
            <Navbar />
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="page-content">
                            <slot />
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-12">
          <GeneralInquiry />
        </div> -->
                </div>
            </div>
            <Sidebar />
            <Whatsapp />
        </Body>
    </Html>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';

const head = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
});
const { state } = useStore();

const clickEvent = () => {
    state.functions.searchList = false;
    state.functions.docMenu = false;
    state.functions.sort = false;
    state.functions.profileMenu = false;
    state.functions.showFlyButton = false;
    state.functions.bodyOverFlow = false;
    state.functions.showNotification = false;
};

const bodyClass = computed(() => state.functions.bodyOverFlow);
const htmlAttrs = computed(() => head.value.htmlAttrs!);
</script>

<style lang="scss" scoped>
.page-content {
    min-height: calc(100vh - 660px);
    padding-bottom: 3rem;

    @include media(1200) {
        min-height: calc(100vh - 818px);
    }

    @include media(768) {
        min-height: calc(100vh - 1024px);
    }

    @include media(425) {
        min-height: calc(100vh - 1190px);
    }
}
</style>
