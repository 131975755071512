<template>
    <Toast />
    <div>
        <Head>
            <Title>Ambassador | {{ $t('loginText') }}</Title>
        </Head>
        <header id="header" class="header-section vh-100">
            <div class="row m-0 d-flex vh-100">
                <div class="col-md-7 logo-section d-flex justify-content-center align-items-center">
                    <div class="layer"></div>
                    <div class="left-container w-100">
                        <div class="logo-container">
                            <img src="/assets/img/common-imgs/main_logo.svg" alt="Acceptix" />
                        </div>
                    </div>
                </div>

                <div class="col-md-5 login-section d-flex justify-content-center align-items-center">
                    <div class="login-info">
                        <h2>{{ $t('login.Hello') }}</h2>
                        <p class="login-word">{{ $t('loginText') }}</p>
                        <div class="form-box">
                            <form @submit.prevent="subDomain ? login() : getCode()">
                                <!-- Enter Code -->
                                <div v-if="!subDomain" class="flex flex-column gap-2 input-box mb-3">
                                    <InputText class="w-100" v-model.trim="code" placeholder="Enter your code" />
                                    <span v-if="codeInvalid" class="validation-message">Invalid Code!</span>
                                </div>

                                <!-- Enter Email -->
                                <div v-if="subDomain" class="flex flex-column gap-2 input-box mb-3">
                                    <InputText
                                        :class="[{ 'p-invalid': emailError }, { 'p-invalid': emailErrorBorder }]"
                                        id="Email"
                                        class="w-100"
                                        v-model.trim="email"
                                        aria-describedby="email-help"
                                        :placeholder="$t('profile.email')"
                                        @input="validateEmail"
                                    />
                                    <InputComponentsInputMessage class="text-start" v-if="emailError">
                                        {{ emailMessage }}
                                    </InputComponentsInputMessage>
                                </div>

                                <!-- Enter Password -->
                                <div v-if="subDomain" class="flex flex-column gap-2 input-box">
                                    <Password
                                        v-model.trim="passwordText"
                                        id="Password"
                                        type="password"
                                        class="w-100"
                                        :class="[{ 'p-invalid': passwordError }, { 'p-invalid': passwordErrorBorder }]"
                                        :placeholder="$t('profile.password')"
                                        :promptLabel="$t('profile.password')"
                                        :weakLabel="$t('weak')"
                                        :mediumLabel="$t('medium')"
                                        :strongLabel="$t('strong')"
                                        :feedback="false"
                                        toggleMask
                                    />
                                    <InputComponentsInputMessage v-if="passwordError">
                                        {{ $t('Pleaseenteryourpassword') }}
                                    </InputComponentsInputMessage>
                                </div>
                                <InputComponentsInputMessageWorn v-if="alertMasg" class="mt-3 mb-0">
                                    {{ $t('PleaseenteravalidEmailorpassword') }}
                                </InputComponentsInputMessageWorn>

                                <!-- Remember me -->
                                <div v-if="subDomain" class="remember-me d-flex justify-content-between">
                                    <div class="d-flex gap-2 align-items-center remember-box-item">
                                        <Checkbox v-model="checked" :binary="true" inputId="rememberme" />
                                        <label for="rememberme"> {{ $t('login.rememberme') }}</label>
                                    </div>
                                    <p>
                                        <LocLink class="forget-password" to="/auth/forgot-password">
                                            {{ $t('login.Forgotpassword') }}
                                        </LocLink>
                                    </p>
                                </div>
                                <div v-if="!subDomain">
                                    <button
                                        class="submit-btn"
                                        :class="{ 'btn-disabled': !code }"
                                        type="submit"
                                        v-if="!codeLoading"
                                        :disabled="!code"
                                    >
                                        Submit
                                    </button>
                                    <Button v-else icon="pi pi-spin pi-spinner" disabled />
                                </div>

                                <div v-if="subDomain">
                                    <button
                                        class="submit-btn"
                                        :class="{ 'btn-disabled': disabled }"
                                        type="submit"
                                        v-if="!loginLoading"
                                        :disabled="disabled"
                                    >
                                        {{ $t('loginText') }}
                                    </button>
                                    <Button v-else icon="pi pi-spin pi-spinner" disabled />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import api from '@/api';
import { useFcm } from '@/composables/useFcm';

const { getFcm } = useFcm();

definePageMeta({
    layout: 'auth',
});

export default {
    mounted() {
        this.showAlert();
    },
    computed: {
        thename() {
            return useCookie('name').value;
        },
        disabled() {
            return this.emailError || this.passwordError;
        },
    },
    data() {
        return {
            remember: true,
            checked: false,
            code: '',
            subDomain: '',
            email: '',
            passwordText: '',
            emailMessage: '',
            emailErrorBorder: false,
            passwordErrorBorder: false,
            emailError: false,
            passwordError: false,
            emailField: false,
            alertMasg: false,
            loginLoading: false,
            codeLoading: false,
            codeInvalid: false,
            hide: false,
        };
    },
    methods: {
        showAlert() {
            if (this.$store.state.login.showAlert) {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('loginFirst'),
                    life: 2000,
                });
                this.$store.state.login.showAlert = false;
            }
        },
        showPassword() {
            this.hide = !this.hide;
        },
        validateEmail() {
            this.emailError = false;
            const emailRegex = /^(?![0-9])[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isValidEmail = emailRegex.test(this.email);
            if (!this.isValidEmail) {
                this.emailError = true;
                this.emailMessage = 'Please enter a valid email address';
            } else {
                this.emailMessage = '';
            }
        },
        passwordValidate() {
            this.passwordError = !(this.passwordText.length >= 8 && this.passwordText.length <= 30);
        },
        getCode() {
            this.codeInvalid = false;
            this.codeLoading = true;

            api.post(`https://${useRuntimeConfig().public.baseURL}/check-company`, {
                code: this.code,
            })
                .then(async res => {
                    this.subDomain = res.data.data.subdomain;
                    localStorage.setItem('subdomain', res.data.data.subdomain);
                })
                .catch(error => {
                    this.codeInvalid = true;
                    this.codeLoading = false;
                });
        },
        async login() {
            const self = this;
            this.emailErrorBorder = false;
            this.passwordErrorBorder = false;
            this.emailError = false;
            this.emailField = false;
            this.passwordError = false;
            if (this.email === '') {
                this.emailError = true;
                this.emailField = true;
                this.emailMessage = this.$t('enterEmail');
                if (this.passwordText === '') {
                    this.passwordError = true;
                }
                return false;
            } else if (this.passwordText === '') {
                this.passwordError = true;
                return false;
            }
            this.loginLoading = true;
            api.post(`https://${this.subDomain}.${useRuntimeConfig().public.baseURL}/login`, {
                email: this.email,
                password: this.passwordText,
            })
                .then(async res => {
                    waitForCookie('token', res.data.data.access_token)
                        .then(() => {
                            localStorage.setItem('userlogin', true);
                            localStorage.setItem('email', res.data.data.email);
                            localStorage.setItem('token', res.data.data.access_token);
                            //Store user info in local storage
                            let userInfo = {
                                subDomain: self.subDomain,
                                adminID: res.data.data.id,
                                name: res.data.data.name,
                                email: res.data.data.email,
                                image: res.data.data.image_url,
                                phone: res.data.data.phone,
                                token: res.data.data.access_token,
                                role: res.data.data.roles[0].name,
                            };
                            localStorage.setItem('subagent-userInfo', JSON.stringify(userInfo));
                            const subagent_userInfo = useCookie('subagent_userInfo');
                            subagent_userInfo.value = JSON.stringify(userInfo);
                            const apiEndpoint = useCookie('apiEndpoint');
                            apiEndpoint.value = JSON.stringify(userInfo);
                            this.$store.dispatch('profileImg/setProfileImage', res.data.data.image_url);

                            self.loginLoading = false;

                            const showAlert = useCookie('showAlert');
                            showAlert.value = null;
                            const access_token_value = res.data.data.access_token;
                            //ask for notification permission from browser
                            Notification.requestPermission().then(function (permission) {
                                if (permission === 'granted') {
                                    getFcm(access_token_value);
                                    self.$router.push(self.localePath('/'));
                                } else {
                                    self.$toast.add({
                                        severity: 'error',
                                        summary: self.$t('NotificationsError'),
                                        detail: self.$t('NotificationsErrorDetails'),
                                        life: 2000,
                                    });
                                    setTimeout(() => {
                                        self.$router.push(self.localePath('/'));
                                    }, 7000);
                                }
                            });
                        })
                        .catch(error => {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('error'),
                                detail: this.$t('somethingWentWrong'),
                                life: 2000,
                            });
                        });
                })
                .catch(function (error) {
                    self.emailErrorBorder = true;
                    self.passwordErrorBorder = true;
                    self.alertMasg = true;
                    self.loginLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.login-button {
    .p-button-label {
        @extend .min-title-font;
        font-size: 20px;
    }
}
</style>
<style lang="scss" scoped>
.loading-box-button {
    width: fit-content;
    margin: 0 auto;
}

.header-section {
    .logo-section {
        position: relative;
        z-index: 0;
        background: linear-gradient(180deg, $light-blue1 0%, $light-blue1 20%, $primary-color 100%);

        .left-container {
            padding-inline-start: toRem(204);

            .logo-container {
                width: toRem(295);

                img {
                    width: 100%;
                }
            }
        }

        .layer {
            @include position($position: absolute, $top: 20%, $bottom: 0, $left: 12%, $right: 0, $z-index: -1);
            background-repeat: no-repeat;
            background-size: 85%;
            background-image: url(/assets/img/login-imgs/layer-img/layer_image.svg);
            opacity: 0.7;
        }
    }

    .login-section {
        .login-info {
            width: 60%;
        }

        .form-box {
            .validation-message {
                display: block;
                font-size: 14px;
                color: $light-orange;
            }
        }

        .login-inputs {
            i {
                @include position($position: absolute, $top: toRem(14), $right: toRem(20));
                cursor: pointer;
            }

            .icon-eye-slash {
                font-size: toRem(25);
                @include position($position: absolute, $top: toRem(12), $right: toRem(20));
            }

            input {
                @include inputGenerator(
                    $padding: toRem(10) toRem(50) toRem(10) toRem(16),
                    $border-radius: toRem(4),
                    $width: 100%,
                    $border: toRem(1) solid $dark6,
                    $font-family: $font-m-sb,
                    $placeholder-color: $dark2,
                    $placeholder-font-size: toRem(14),
                    $placeholder-font-family: $font-m-l
                );
            }
        }

        h2 {
            font-family: $font-m-eb;
            font-size: toRem(36);
            color: $primary-color;
        }

        .login-word {
            font-size: toRem(20);
            margin-bottom: toRem(20);
            color: $dark-grey1;
            font-family: $font-m-m;
        }
    }
}

.remember-me {
    margin: toRem(32) 0 toRem(30) 0;

    p,
    a,
    label {
        color: $dark2;
        font-family: $font-m-m;
        cursor: pointer;
        font-size: toRem(14);
    }

    .forget-password {
        p {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.submit-btn {
    @include btnGenerator(
        $width: 100%,
        $padding: 0.75rem 0,
        $border-radius: toRem(10),
        $font-size: toRem(16),
        $background-color: $primary-color,
        $font-family: $font-o-sb,
        $border: none,
        $color: $white,
        $box-shadow: 0 toRem(4) toRem(8) 0 $dark-blue2
    );

    &.btn-disabled {
        opacity: 0.7;
    }
}

.p-button.p-component {
    width: 100%;
    border-radius: toRem(10);
    padding: 0.88rem 0;
}

.new-account {
    margin-top: toRem(32);
    font-size: toRem(14);
    color: $dark-grey3;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

@media (min-width: 992px) {
    .header-section {
        .logo-section {
            .left-container {
                padding-inline-start: toRem(120);

                .logo-container {
                    width: toRem(250);
                }
            }

            .layer {
                @include position($position: absolute, $top: 20%, $bottom: 0, $left: 12%, $right: 0, $z-index: -1);
                background-size: 95%;
            }
        }

        .login-section {
            padding: 1.5rem;

            .login-info {
                width: 60%;
            }

            .login-inputs {
                i {
                    font-size: toRem(12);
                    @include position($position: absolute, $top: toRem(15), $right: toRem(20));
                }

                .icon-eye-slash {
                    font-size: toRem(20);
                    @include position($position: absolute, $top: toRem(12), $right: toRem(19));
                }

                input {
                    @include inputGenerator(
                        $padding: toRem(8) toRem(45) toRem(8) toRem(14),
                        $border-radius: toRem(4),
                        $width: 100%,
                        $border: toRem(1) solid $dark6,
                        $font-family: $font-m-sb,
                        $placeholder-color: $dark2,
                        $placeholder-font-family: $font-m-l,
                        $placeholder-font-size: toRem(12)
                    );
                }
            }

            h2 {
                font-size: toRem(36);
            }

            .login-word {
                font-size: toRem(17);
                margin-bottom: toRem(17);
            }
        }
    }

    .remember-me {
        p,
        a,
        label {
            font-size: toRem(11.5);
        }
    }

    .submit-btn {
        @include btnGenerator(
            $width: 100%,
            $padding: 0.75rem 0,
            $border-radius: toRem(10),
            $font-size: toRem(20),
            $background-color: $primary-color,
            $font-family: $font-o-sb,
            $border: none,
            $color: $white,
            $box-shadow: 0 toRem(4) toRem(8) 0 $dark-blue2
        );
    }

    .new-account {
        margin-top: toRem(29);
        font-size: toRem(13);
    }
}

@media (min-width: 1900px) {
    .header-section {
        .logo-section {
            .left-container {
                padding-inline-start: toRem(204);

                .logo-container {
                    width: toRem(295);
                }
            }

            .layer {
                @include position($position: absolute, $top: 20%, $bottom: 0, $left: 12%, $right: 0, $z-index: -1);
                background-size: 90%;
            }
        }

        .login-section {
            padding: 0;

            .login-info {
                width: 50%;
            }

            .login-inputs {
                i {
                    @include position($position: absolute, $top: toRem(16), $right: toRem(20));
                }

                .icon-eye-slash {
                    font-size: toRem(25);
                    @include position($position: absolute, $top: toRem(12), $right: toRem(18));
                }

                input {
                    @include inputGenerator(
                        $padding: toRem(10) toRem(50) toRem(10) toRem(16),
                        $border-radius: toRem(4),
                        $width: 100%,
                        $border: toRem(1) solid $dark6,
                        $font-family: $font-m-sb,
                        $placeholder-color: $dark2,
                        $placeholder-font-family: $font-m-l,
                        $placeholder-font-size: toRem(14)
                    );
                }
            }

            h2 {
                font-size: toRem(41);
            }

            .login-word {
                font-size: toRem(20);
                margin-bottom: toRem(20);
            }
        }
    }

    .remember-me {
        p,
        a,
        label {
            font-size: toRem(14);
        }
    }

    .submit-btn {
        @include btnGenerator(
            $width: 100%,
            $padding: 0.75rem 0,
            $border-radius: toRem(10),
            $font-size: toRem(20),
            $background-color: $primary-color,
            $font-family: $font-o-sb,
            $border: none,
            $color: $white,
            $box-shadow: 0 toRem(4) toRem(8) 0 $dark-blue2
        );
    }

    .new-account {
        margin-top: toRem(32);
        font-size: toRem(14);
    }
}

[dir='rtl'] {
    .header-section {
        .login-section {
            h2,
            p,
            label,
            a,
            .submit-btn {
                font-family: 'Cairo', sans-serif;
            }
        }
    }
}
</style>
