export const state = () => ({
    files: [],
    image: '',
    profile: [],
    nationality: [],
    loading: true,
    first_name: '',
    middle_name: '',
    last_name: '',
    userName: ' ',
    email: '',
    university: '',
    degree: '',
    major: '',
    GPA: '',
    phone_code: null,
    graduation_year: '',
    error: '',
    showError: '',
    token: null,
});

export const actions = {
    getProfile() {
        // const token = useCookie("token");
        // state.token = token.value;
        // state.loading = true;
        // api
        //   .get("/auth/profile")
        //   .then((res) => {
        //     state.loading = false;
        //     state.image = res.data.data.user.image;
        //     state.profile = res.data.data.user;
        //     state.first_name = res.data.data.user.first_name;
        //     state.middle_name = res.data.data.user.middle_name;
        //     state.last_name = res.data.data.user.last_name;
        //     state.userName = res.data.data.user.name;
        //     state.email = res.data.data.user.email;
        //     const phoneCode = state.profile.phone_code;
        //     const show = phoneCode?.flag ? phoneCode.flag : phoneCode.emoji;
        //     state.phone_code = {
        //       id: state.profile.phone_code.id,
        //       show: show,
        //       code: state.profile.phone_code.code,
        //       name: state.profile.phone_code.name,
        //     };
        //     state.nationality = {
        //       id: state.profile.nationality.id,
        //       name: state.profile.nationality.name,
        //     };
        //     state.university = res.data.data.user.additional_info.university;
        //     state.degree = res.data.data.user.additional_info.degree;
        //     state.major = res.data.data.user.additional_info.major;
        //     state.GPA = res.data.data.user.additional_info.GPA;
        //     state.graduation_year =
        //       res.data.data.user.additional_info.graduation_year;
        //   })
        //   .catch(async (err) => {
        //     if (err?.response?.status === 401 && token) {
        //       state.showError = true;
        //       if (token) {
        //         const token2 = useCookie("token", { maxAge: -1 });
        //         await nextTick();
        //         token2.value = null;
        //         await nextTick();
        //       }
        //       // localStorage.removeItem('token');
        //       // localStorage.removeItem('userlogin');
        //       // location.reload();
        //       this.$store.state.login.logout = true;
        //       this.$router.push(this.localePath("/"));
        //     } else {
        //       state.error = err;
        //     }
        //   });
    },
    async removeProfile({ state }) {
        const token = useCookie('token', { maxAge: -1 });
        await nextTick();
        token.value = null;
        state.token = null;
        await nextTick();
        state.loading = false;
        state.image = '';
        state.profile = '';
        state.first_name = '';
        state.middle_name = '';
        state.last_name = '';
        state.userName = '';
        state.phone_code = [];
        state.nationality = [];
        state.university = '';
        state.degree = '';
        state.email = '';
        state.major = '';
        state.GPA = '';
        state.graduation_year = '';
        state.studentID = null;
        localStorage.removeItem('email');
        localStorage.removeItem('userlogin');
        localStorage.removeItem('subdomain');
        localStorage.removeItem('subagent-userInfo');
        localStorage.removeItem('token');
        useCookie('apiEndpoint').value = null;
        useCookie('subagent_userInfo').value = null;
    },

    getDocuments({ state }, payload) {
        state.loading = true;
        if (payload) {
            state.studentID = payload;
        }
        const { $axios } = useNuxtApp();
        $axios
            .get(`/students/${state.studentID}/uploaded-documents`)
            .then(res => {
                state.files = res.data.data;
                state.loading = false;
            })
            .catch(() => {
                state.loading = false;
            });
    },
};
