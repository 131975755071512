<template>
    <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
        <Body @click="clickEvent">
            <noscript
                ><iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-W6Q7NQWS"
                    height="0"
                    width="0"
                    style="display: none; visibility: hidden"
                ></iframe
            ></noscript>
            <Navbar />
            <div class="page-content">
                <slot />
            </div>
            <Sidebar />
            <Whatsapp />
        </Body>
    </Html>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';

const head = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
});
const store = useStore();
const state = store.state;

const clickEvent = () => {
    state.functions.searchList = false;
    state.functions.docMenu = false;
    state.functions.sort = false;
    state.functions.profileMenu = false;
    state.functions.showFlyButton = false;
    state.functions.bodyOverFlow = false;
    state.functions.showNotification = false;
};

const htmlAttrs = computed(() => head.value.htmlAttrs!);
</script>

<style lang="scss" scoped>
.page-content {
    min-height: calc(100vh - 660px);
    // @include dir(rtl) {
    //   padding-right: 8rem;
    // }
    // @include dir(ltr) {
    //   padding-left: 8rem;
    // }
    padding-bottom: 3rem;

    @include media(1200) {
        min-height: calc(100vh - 818px);
    }

    @include media(768) {
        min-height: calc(100vh - 511px);
    }

    @include media(425) {
        min-height: calc(100vh - 579px);
    }
}
</style>
