import api from '@/api';

export const state = () => ({
    cities: [],
});

export const actions = {
    getCities({ state }, apiURL) {
        api.get(apiURL).then(res => {
            state.cities = res.data.data;
        });
    },
};
