import api from '@/api';
export const state = () => ({
    degreeType: [],
    degreeTypeList: [],
});

export const actions = {
    getDegreeType({ state }) {
        const subDomain = useCookie('subagent_userInfo').value.subDomain;
        api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/degree-type`).then(res => {
            state.degreeType = res.data.data;
            state.degreeTypeList = res.data.data.map(item => {
                return { id: item.id, name: item.name, query: `&degree_type_ids[]=${item.id}` };
            });
        });
    },
};
