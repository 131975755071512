import api from '@/api';
export const state = () => ({
    loading: true,
    courseList: [],
    data: [],
});

export const actions = {
    getCoursesList({ state }, payload) {
        const subDomain = useCookie('subagent_userInfo').value.subDomain;
        api.get(
            `https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?per_page=${payload}`
        ).then(res => {
            state.loading = false;
            state.data = res.data.data;
            state.courseList = state.data = [
                ...state.data.map(item => ({
                    id: item.id,
                    type: 'courses',
                    name: item.name,
                    offer: item.fees,
                    offerCount: item.sale_percentage + '%',
                    price: item.fees_after_sale,
                    has_sale: item.has_sale,
                    location: item.location,
                    image: item.image,
                    favourite: item.favourite,
                    course_type: item.course_type,
                    currency: item.currency,
                })),
            ];
        });
    },
};
